@import '__importable.scss';
.list {
	width: 100%;
	height: auto;
	overflow-y: scroll;

	@media screen and (min-width: $media-lg) {
		overflow-y: auto;
	}
	&--white {
		background-color: $gray-1;

		.list-item,
		.list-placeholder {
			background-color: $white;
		}
	}
}
.list-custom {
	width: 100%;
	height: auto;
	overflow-y: visible;
}

.list-item,
.list-placeholder {
	background-color: $gray-0;
	justify-content: flex-start;
	width: 100%;
	padding: 0.8rem 1.2rem;
	margin-bottom: 1.6rem;
	border: 1px solid $gray-2;
	border-radius: 0.8rem;

	&:last-child {
		margin-bottom: 0;
	}

	&-image {
		margin: 0;
		width: 64px;
		height: auto;
		flex: 0 0 auto;
	}

	&-delete {
		margin-left: auto;
	}
}
.list-item-custom {
	width: 100%;
	margin-bottom: 1.6rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.list-placeholder {
	background-color: $gray-1;
	border-style: dashed;
	border-color: $gray-2;

	&-image {
		mix-blend-mode: darken;
	}

	p {
		color: $gray-4;
	}

	&--normal {
		padding: 1.2rem;
	}
}
